import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import { useTranslation } from 'react-i18next';
import {
  useTransferDispatch,
  useTransferState,
} from '../../context/TransferContext';
import Button from '../../shared/Button';
import Form from '../../shared/Form';
import TextField from '../../shared/TextField';
import SelectField from '../../shared/SelectField';
import Checkbox from '../../shared/Checkbox';
import Container from '../../shared/Container';
import Page from '../../shared/Page';
import './TransferRequestPage.scss';
import personalCodeValidation from '../../hooks/personalCodeValidation';
const TransferRequestPage = () => {
  const { post: formPost, response: formResponse } = useFetch(
    `${process.env.REACT_APP_SIGN_API_BASE}/pdf`,
  );
  const { request: companiesRequest, loading } = useFetch(
    `${process.env.REACT_APP_SIGN_API_BASE}/companies`,
  );
  const [specialistList, setSpecialistList] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedSpecialist, setSelectedSpecialist] = useState(null);
  const [centersList, setCentersList] = useState(null);
  const [selectedCenterSpecialist, setSelectedCenterSpecialist] =
    useState(null);
  const [centerSpecialistList, setCenterSpecialistList] = useState(null);
  const [showText, setShowText] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const history = useHistory();
  const dispatch = useTransferDispatch();
  const transferState = useTransferState();
  const { t } = useTranslation();
  const [validatePersonalCode] = personalCodeValidation();
  const specialistSelectRef = useRef();
  const centersSelectRef = useRef();
  const [tabIndex, setTabIndex] = useState(1);
  const [change, setChange] = useState(false);
  const centersSpecialistSelectRef = useRef();
  const [
    agreedWithTermsAndConditionsDefaultChecked,
    setAgreedWithTermsAndConditionsDefaultChecked,
  ] = useState(false);
  const [
    agreedWithTermsAndConditionsListDefaultChecked,
    setAgreedWithTermsAndConditionsListDefaultChecked,
  ] = useState(false);
  const formRef = useRef();
  const initialLoad = useRef(true);
  const initialLoadSecond = useRef(true);

  useEffect(() => {
    let isMounted = true;

    if (transferState.centerId) {
      setShowText(true);
    }
    if (transferState.formType === 1 || transferState.formType === 2) {
      setTabIndex(transferState.formType);
    }

    companiesRequest
      .get()
      .then((companies) => {
        if (isMounted) {
          let comp = companies;
          let list = [];
          comp.forEach((item) => {
            let healthCenters = [];
            let items = [];
            item.healthCenters.forEach((healthCenter) => {
              let healthCenterSpecialists = [];
              healthCenter.healthCenterSpecialists.forEach((hcs) => {
                healthCenterSpecialists.push({
                  value: hcs.healthCenterSpecialistId,
                  label: hcs.name,
                });
              });
              healthCenters.push({
                value: healthCenter.healthCenterId,
                label: healthCenter.title,
                healthCenterSpecialists: healthCenterSpecialists,
              });
            });
            item.items.forEach((specialist) => {
              items.push({ value: specialist.id, label: specialist.title });
            });

            list.push({
              value: item.id,
              label: item.title,
              healthCenters: healthCenters,
              items: items,
            });
          });

          const selected = list.find((item) => {
            return item.value === transferState.companyId;
          });

          setSelectedCompany(selected);

          setAgreedWithTermsAndConditionsDefaultChecked(
            transferState.agreedWithTermsAndConditions,
          );
          setAgreedWithTermsAndConditionsListDefaultChecked(
            transferState.agreedWithTermsAndConditionsList,
          );

          if (selected) {
            let specialistList;
            if (transferState.formType === 1) {
              specialistList = [
                { value: -1, label: t('transfer.specialistDefault') },
              ].concat(
                list.find((item) => {
                  return item.value === transferState.companyId;
                }).items,
              );
            } else {
              specialistList = list.find((item) => {
                return item.value === transferState.companyId;
              }).items;
            }
            setSpecialistList(specialistList);
            setSelectedSpecialist(
              specialistList.find(
                (specialist) => specialist.value === transferState.specialistId,
              ),
            );
            const centersItem = selected.healthCenters.find((item) => {
              return item.value === transferState.centerId;
            });

            if (centersItem) {
              setCentersList(
                list.find((item) =>
                  item.healthCenters.find(
                    (item) => item.value === transferState.centerId,
                  ),
                ).healthCenters,
              );
              setSelectedCenter(centersItem);
              setCenterSpecialistList(
                [
                  { value: -1, label: t('transfer.centerSpecialistDefault') },
                ].concat(centersItem.healthCenterSpecialists),
              );
              setSelectedCenterSpecialist(
                [{ value: -1, label: t('transfer.centerSpecialistDefault') }]
                  .concat(centersItem.healthCenterSpecialists)
                  .find(
                    (specialist) =>
                      specialist.value === transferState.centerSpecialistId,
                  ),
              );
            }
          }
          setCompanyList(list);
        }
      })
      .catch(() => { });
    return () => {
      isMounted = false;
    };
  }, [companiesRequest, transferState]);

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (specialistList === null) {
      return;
    }
    if (tabIndex === 2) {
      formRef.current.setErrors({});
      if (selectedSpecialist.value === -1) setSelectedSpecialist(null);
      setSpecialistList((prev) => prev.filter((s) => s.value !== -1));

      if (formRef.current.values.specialistId === -1) {
        formRef.current.setFieldValue('specialistId', null);
      }
      formRef.current.setErrors({});

      if (showText) {
        formRef.current.setFieldValue('centerId', null);
        formRef.current.setFieldValue('centerSpecialistId', null);
        formRef.current.setFieldValue(
          'agreedWithTermsAndConditionsList',
          false,
        );
        if (selectedCenterSpecialist !== null) {
          setSelectedCenterSpecialist(null);
        }
        setShowText(false);
      }
    } else if (tabIndex === 1) {
      specialistList.unshift({
        value: -1,
        label: t('transfer.specialistDefault'),
      });
      setSpecialistList(specialistList);
      if (!formRef.current.values.specialistId) {
        formRef.current.setFieldValue('specialistId', -1);
        formRef.current.setFieldError('specialistId', null);
        formRef.current.setErrors({});
      }
      setChange((prev) => !prev);
    }
  }, [tabIndex]);

  useEffect(() => { }, [tabIndex]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    if (!selectedSpecialist) {
      setSelectedSpecialist(
        specialistList?.find((specialist) => specialist.value === -1),
      );
    }
  }, [change]);

  useEffect(() => {
    const initialLoad = Object.keys(transferState).length === 0;

    if (showText && !initialLoad && !transferState.centerSpecialistId) {
      let company = companyList.find(
        (item) => item.value === transferState.companyId,
      );
      setCentersList(
        company.healthCenters?.length !== 0 && company.healthCenters,
      );
      setCenterSpecialistList(null);
      centersSpecialistSelectRef.current.select.setValue(null);
    }

    if (showText && !initialLoad && centersList) {
      setCenterSpecialistList(null);
      centersSpecialistSelectRef.current.select.setValue(null);
    }

    if (showText && !centersList && initialLoad) {
      setCentersList(null);
      setCenterSpecialistList(null);
      return;
    }

    if (showText && initialLoad) {
      //const isEmpty = Object.keys(transferState).length === 0;
      centersSelectRef.current.select.setValue(null);
      centersSpecialistSelectRef.current.select.setValue(null);
      setCenterSpecialistList(null);
    }
  }, [showText]);

  Yup.setLocale({
    mixed: {
      required: t('validation.requiredField'),
      notType: t('validation.invalidFormat'),
    },
  });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required()
      .test('len', 'Vardas turi būti bent 3 simbolių ilgio', (val) => {
        return val && val.toString().length >= 3;
      })
      .trim(),
    lastName: Yup.string()
      .required()
      .test('len', 'Pavardė turi būti bent 3 simbolių ilgio', (val) => {
        return val && val.toString().length >= 3;
      })
      .trim(),
    personalCode: Yup.number()
      .required()
      .test('len', t('validation.invalidFormat'), (val) => {
        return validatePersonalCode(val);
      }),
    phoneNumber: Yup.string()
      .required(t('validation.requiredField'))
      .test('checkFormat', t('validation.number'), function (val) {
        const phoneRegex = /^[+]{1}(370)(\d{8})+$/;
        if (val && phoneRegex.test(val)) {
          return true;
        }
        return false;
      }),
    email: Yup.string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.requiredField'))
      .trim()
      .test(
        'latinOnlyEmail',
        t('validation.latinOnlyEmail'),
        function (value) {
          if (!value) return true;
          const latinOnlyRegex = /^[\u0000-\u007F]+$/;
          return latinOnlyRegex.test(value);
        }
      ),
    secondEmail: Yup.string()
      .email(t('validation.invalidEmail'))
      .oneOf([Yup.ref('email'), null], 'El. pašto adresai turi sutapti!')
      .required(t('validation.requiredField'))
      .trim()
      .test(
        'latinOnlyEmail',
        t('validation.latinOnlyEmail'),
        function (value) {
          if (!value) return true;
          const latinOnlyRegex = /^[\u0000-\u007F]+$/;
          return latinOnlyRegex.test(value);
        }
      ),
    address: Yup.string()
      .required()
      .test('len', 'Adresas turi būti bent 5 simbolių ilgio', (val) => {
        return val && val.length >= 5;
      })
      .trim(),
    companyId: Yup.string().required().trim(),
    // specialistId: Yup.string().required().trim(),
    specialistId: Yup.number().required(),
    centerId: showText && Yup.string().required().trim(),
    centerSpecialistId: showText && Yup.string().required().trim(),
    agreedWithTermsAndConditions: Yup.boolean().isTrue(
      t('validation.requiredField'),
    ),
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    personalCode: '',
    phoneNumber: '',
    email: '',
    address: '',
    companyId: '',
    specialistId: '',
    centerId: '',
    centerSpecialistId: null,
    agreedWithTermsAndConditions: false,
    agreedWithTermsAndConditionsList: false,
    ...transferState,
  };

  const handleSubmit = async (data, { setSubmitting, setErrors }) => {
    setSubmitting(true);

    await formPost('', { ...data, formType: tabIndex }); //const request =

    if (formResponse.status >= 400) {
      const response = await formResponse.json();
      setErrors(response.errors);
    } else {
      const response = await formResponse.blob();
      dispatch({
        type: 'SET_DATA',
        data: {
          ...data,
          pdf: response,
          formType: tabIndex,
        },
      });

      history.push('/transfer-request/preview', { type: tabIndex });
    }
    setSubmitting(false);
  };

  return (
    <Page loading={companyList.length === 0 || loading}>
      <Container className="transfer-request-page" narrow={true}>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ setFieldValue, errors, isSubmitting, values, validateForm }) => (
            <FormikForm style={{ marginTop: '10px' }}>
              <div className="tabList">
                <div
                  className={['tabItem', tabIndex === 1 && 'activeTab'].join(
                    ' ',
                  )}
                  onClick={() => setTabIndex(1)}
                >
                  <div>
                    <p style={{ fontSize: '20px', marginBottom: '5px' }}>
                      PRISIRAŠYTI PRIE KLINIKOS
                    </p>
                    <span style={{ fontSize: '14px' }}>
                      Prašymas leisti gydytis Affidea | Endemik klinikoje
                    </span>
                  </div>
                </div>
                <div
                  className={['tabItem', tabIndex === 2 && 'activeTab'].join(
                    ' ',
                  )}
                  onClick={() => {
                    setTabIndex(2);
                  }}
                >
                  <div style={{ marginBottom: '10px' }}>
                    <p style={{ fontSize: '20px', marginBottom: '5px' }}>
                      KEISTI ŠEIMOS GYDYTOJĄ
                    </p>
                    <span style={{ marginBottom: '5px', fontSize: '14px' }}>
                      Prašymas leisti gydytis pas kitą Affidea | Endemik
                      klinikos gydytoją, skirtas jau prisirašiusiems pacientams,
                      norintiems pakeisti šeimos gydytoją
                    </span>
                  </div>
                </div>
              </div>

              <Form noBorderRadiusTop={true}>
                <Form.Group>
                  {companyList.length > 0 && (
                    <>
                      <Form.Row>
                        <SelectField
                          error={errors.companyId}
                          defaultValue={selectedCompany}
                          name="companyId"
                          label={t('transfer.fields.company')}
                          options={companyList}
                          onChange={(value) => {
                            let specialistList;

                            if (tabIndex === 1) {
                              specialistList = [
                                {
                                  value: -1,
                                  label: t('transfer.specialistDefault'),
                                },
                              ].concat(
                                companyList.find((item) => item.value === value)
                                  .items,
                              );
                            } else {
                              specialistList = companyList.find(
                                (item) => item.value === value,
                              ).items;
                              specialistList = specialistList.filter(
                                (s) => s.value !== -1,
                              );
                            }
                            setSpecialistList(specialistList);
                            if (tabIndex === 1) {
                              setSelectedSpecialist(
                                specialistList?.find(
                                  (specialist) => specialist?.value === -1,
                                ),
                              );
                            } else {
                              setSelectedSpecialist(null);
                            }

                            if (tabIndex === 1) {
                              setFieldValue('specialistId', -1);
                            } else {
                              setFieldValue('specialistId', null);
                            }

                            setFieldValue('companyId', value);
                            if (showText) {
                              centersSelectRef.current.select.setValue(null);
                              centersSpecialistSelectRef.current.select.setValue(
                                null,
                              );
                              setFieldValue('centerSpecialistId', undefined);
                              setFieldValue('centerId', undefined);
                            }
                            let company = companyList.find(
                              (item) => item.value === value,
                            );
                            setCentersList(
                              company.healthCenters?.length !== 0 &&
                              company.healthCenters,
                            );
                            setCenterSpecialistList(null);
                          }}
                        />
                      </Form.Row>
                      {((tabIndex === 1 &&
                        specialistList &&
                        specialistList.length !== 1) ||
                        (tabIndex === 2 &&
                          specialistList &&
                          specialistList.length !== 0)) && (
                          <Form.Row>
                            <SelectField
                              innerRef={specialistSelectRef}
                              //defaultValue={selectedSpecialist}
                              disabled={
                                specialistList === null ||
                                specialistList === undefined
                              }
                              error={errors.specialistId}
                              value={selectedSpecialist}
                              label={t('transfer.fields.specialist')}
                              name="specialistId"
                              options={specialistList}
                              onChange={(value) => {
                                setFieldValue('specialistId', value);
                                if (specialistList) {
                                  setSelectedSpecialist(
                                    specialistList?.find(
                                      (specialist) => specialist?.value === value,
                                    ),
                                  );
                                  setFieldValue('specialistId', value);
                                }
                              }}
                            />
                          </Form.Row>
                        )}
                      {((tabIndex === 1 &&
                        specialistList &&
                        specialistList.length === 1) ||
                        (tabIndex === 2 &&
                          specialistList &&
                          specialistList.length === 0)) && (
                          <p style={{ fontSize: '14px', fontWeight: 'bold' }}>
                            Atsiprašome, šiuo metu prisirašymas negalimas, prašome
                            kreiptis į klinikos registratūrą
                          </p>
                        )}
                    </>
                  )}
                  <Form.Row>
                    <Field
                      focus={true}
                      label={t('transfer.fields.firstName')}
                      name="firstName"
                      component={TextField}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Field
                      label={t('transfer.fields.lastName')}
                      name="lastName"
                      component={TextField}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Field
                      label={t('transfer.fields.personalCode')}
                      name="personalCode"
                      component={TextField}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Field
                      label={t('transfer.fields.phoneNumber')}
                      name="phoneNumber"
                      component={TextField}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Field
                      label={t('transfer.fields.email')}
                      name="email"
                      component={TextField}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Field
                      label={
                        'Pakartokite el.pašto adresą (patikrinsime nurodytą adresą, kad duomenys nepatektų kitam asmeniui)'
                      }
                      name="secondEmail"
                      component={TextField}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Field
                      label={t('transfer.fields.address')}
                      name="address"
                      component={TextField}
                    />
                  </Form.Row>

                  {specialistList && specialistList.length > 1 && (
                    <Form.Row>
                      <Checkbox
                        name="agreedWithTermsAndConditions"
                        error={errors.agreedWithTermsAndConditions}
                        onChange={(event) => {
                          setFieldValue(
                            'agreedWithTermsAndConditions',
                            event.target.checked,
                          );
                        }}
                        defaultChecked={
                          agreedWithTermsAndConditionsDefaultChecked
                        }
                      >
                        {t('transfer.fields.agreedWithTermsAndConditions')}
                      </Checkbox>
                    </Form.Row>
                  )}

                  {specialistList &&
                    specialistList.length > 1 &&
                    tabIndex === 1 && (
                      <>
                        <Form.Row>
                          <Checkbox
                            onChange={(event) => {
                              setFieldValue(
                                'agreedWithTermsAndConditionsList',
                                event.target.checked,
                              );
                              if (event.target.checked) {
                                setShowText(true);
                              } else {
                                setSelectedCenter(null);
                                setSelectedCenterSpecialist(null);
                                setFieldValue('centerId', null);
                                setFieldValue('centerSpecialistId', null);
                                setShowText(false);
                              }
                            }}
                            defaultChecked={
                              agreedWithTermsAndConditionsListDefaultChecked
                            }
                          >
                            <Form.Row>
                              <span style={{ fontWeight: '600' }}>
                                {t(
                                  'transfer.fields.agreedWithTermsAndConditionsList',
                                )}
                              </span>
                            </Form.Row>
                          </Checkbox>
                        </Form.Row>
                        {showText && (
                          <>
                            <Form.Row>
                              <SelectField
                                innerRef={centersSelectRef}
                                disabled={!centersList}
                                defaultValue={selectedCenter}
                                error={errors.centerId}
                                label={t('transfer.fields.center')}
                                name="centerId"
                                options={centersList}
                                onChange={(value) => {
                                  if (value) {
                                    setFieldValue('centerId', value);
                                    let list = null;
                                    if (
                                      centersList[0].healthCenterSpecialists
                                    ) {
                                      list = [
                                        {
                                          value: -1,
                                          label: t(
                                            'transfer.centerSpecialistDefault',
                                          ),
                                        },
                                      ].concat(
                                        centersList[0].healthCenterSpecialists,
                                      );
                                    }
                                    setCenterSpecialistList(list);

                                    setSelectedCenterSpecialist(
                                      list.find(
                                        (specialist) => specialist.value === -1,
                                      ),
                                    );
                                    setFieldValue('centerSpecialistId', -1);
                                  }
                                }}
                              />
                            </Form.Row>
                            <Form.Row>
                              <SelectField
                                innerRef={centersSpecialistSelectRef}
                                options={centerSpecialistList}
                                // defaultValue={{
                                //   value: -1,
                                //   label: t('transfer.centerSpecialistDefault'),
                                // }}.
                                value={selectedCenterSpecialist}
                                disabled={!centerSpecialistList}
                                error={errors.centerSpecialistId}
                                label={t('transfer.fields.centerSpecialist')}
                                name="centerSpecialistId"
                                onChange={(value) => {
                                  if (centerSpecialistList) {
                                    setSelectedCenterSpecialist(
                                      centerSpecialistList.find(
                                        (specialist) =>
                                          specialist.value === value,
                                      ),
                                    );
                                    setFieldValue('centerSpecialistId', value);
                                  }
                                }}
                              />
                            </Form.Row>
                          </>
                        )}
                        <Form.Row>
                          <div style={{ marginTop: '10px' }}>
                            {t('transfer.fields.informed')}
                          </div>
                        </Form.Row>
                      </>
                    )}
                </Form.Group>
              </Form>
              <div className="mt-4 mt-md-2 d-flex align-center justify-center">
                <Button
                  style={{ marginRight: '15px' }}
                  size="huge"
                  variant="secondary"
                  onClick={async () => {
                    history.push('/');
                  }}
                >
                  {t('transfer.fields.back')}
                </Button>
                <Button
                  loading={isSubmitting}
                  disabled={
                    isSubmitting ||
                    (tabIndex === 1 &&
                      specialistList &&
                      specialistList.length === 1) ||
                    (tabIndex === 2 &&
                      specialistList &&
                      specialistList.length === 0)
                  }
                  size="huge"
                  type="submit"
                >
                  {t('transfer.fields.send')}
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default TransferRequestPage;
