/* eslint-disable jsx-a11y/anchor-is-valid */
import useFetch from 'use-http';
import React, { useEffect, useState, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import Page from '../../../../../shared/Page';
import Styled from '../../UserAccountPage.styles';
import { getQueryString } from '../../../../../constants/visitsQueryStringBuilder';
import { useHistory } from 'react-router-dom';
import {
  useUserDispatch,
  useUserState,
} from '../../../../../context/UserContext';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../../shared/Loader';
import Button from '../../../../../shared/Button/Button';
import Moment from 'react-moment';
import DateRangePicker from '../../../../../shared/DateRangePicker/DateRangePicker';
import { AiOutlineEdit } from 'react-icons/ai';
import Modal from '../../../../../shared/Modal/Modal';

const DoctorInformation = () => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState();
  const history = useHistory();
  const [data, setData] = useState();
  const [search, setSearch] = useState('');
  const [fullData, setFullData] = useState();
  const userState = useUserState();
  const [dateRange, setDateRange] = useState(userState.syncLogsFilter);
  const sessionDispatch = useUserDispatch();
  const { t } = useTranslation();
  const [skip, setSkip] = useState(0);
  const [reload, setReload] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
  const [specialistDescription, setSpecialistDescription] = useState([]);
  const [selectedSpecialistId, setSelectedSpecialistId] = useState();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedLink, setSelectedLink] = useState('');
  const [specialistFullName, setSpecialistFullName] = useState('')
  const [specialistDescriptionLength, setSpecialistDescriptionLength] =
    useState(0);
  const {
    get: getSpecialistsDescription,
    response: getSpecialistsDescriptionResponse,
  } = useFetch(`/SpecialistsDescription`);

  const updateReload = useRef();

  const changeReload = () => {
    updateReload.current = true
    setReload((prev) => !prev);    
    setLoading(true)
  };

  useEffect(() => {
    setSearch('');
    setReload((prev) => !prev);
  }, []);

  useEffect(() => {    
      setSpecialistDescription(data?.slice(0, specialistDescriptionLength));  
  }, [data, specialistDescriptionLength]);

  
  useEffect(() => {
    const fetchData = async () => {
      await getSpecialistsDescription().then((res) => {       
        let sortedRes = res.sort((a, b) =>
          a.specialist.lastName < b.specialist.lastName ? -1 : 1,
        );
        setFullData(sortedRes ?? []);
        setData(sortedRes);
        if(updateReload.current)
        {
          setSpecialistDescription(sortedRes.slice(0, specialistDescriptionLength));          
          updateReload.current = false
          setLoading(false);
          return
        }
        setSpecialistDescription(sortedRes.slice(0, 50));
        setSpecialistDescriptionLength(50)
        setLoading(false);
      });
    };
    fetchData();    
  }, [reload]);

  useEffect(() => {
    if (!search) {
      setData(fullData);
    }
    const filteredData = fullData?.filter((item) => {
      return search
      .toLowerCase()
      .split(/\s+/) // Split search into words by spaces
      .some(word => {
        return item?.specialist?.firstName?.toLowerCase().includes(word) || 
          item?.specialist?.lastName?.toLowerCase().includes(word)
      }
              );
    });
    setData(filteredData);
  }, [search, fullData]);

  const clearSearchField = () => {
    setSearch('');
  };

  return (
    <Page>
      <Styled.DataContainer>
        <Styled.TabTitleWrapper>
          <Styled.TabTitle>Gydytojų informacija</Styled.TabTitle>
          <form
            autocomplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              setSearch(e.target.search.value);
            }}
            onChange={(e) => {
              if (
                e.target.value === undefined ||
                e.target.value === null ||
                e.target.value === ''
              ) {
                setSearch(e.target.value);
              }
            }}
            style={{ display: 'flex' }}
          >
            <input
              type="text"
              id="search"
              placeholder="Paieška"
              name="search"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
            <Button
              style={{ marginRight: '15px', fontSize: '16px' }}
              size="small"
              type="submit"
              onClick={clearSearchField}
            >
              Išvalyti
            </Button>
          </form>
        </Styled.TabTitleWrapper>

        {loading && (
          <Page>
            <Loader />
          </Page>
        )}

        {specialistDescription &&
          specialistDescription.length !== 0 &&
          specialistDescription.map((sp, index) => {
            return (
              <div key={index}>
                <Styled.Visit>
                  <Styled.DoctorInfoListRecord>
                    <div>
                      <AiOutlineEdit
                        onClick={() => {
                          setEditModalVisible(true);
                          setSelectedSpecialistId(sp.specialist.specialistId);
                          setSelectedLink(
                            sp.specialistDescription?.descriptionUrl
                              ? sp.specialistDescription?.descriptionUrl
                              : '',
                          );
                          setSpecialistFullName(`${sp.specialist.firstName.toString().toUpperCase()} ${sp.specialist.lastName.toString().toUpperCase()}`)
                        }}
                      />
                      <Styled.DoctorName>
                        {sp.specialist.firstName.toString().toUpperCase()}{' '}
                        {sp.specialist.lastName.toString().toUpperCase()}
                      </Styled.DoctorName>
                    </div>
                    <Styled.DescriptionUrl>
                      {sp.specialistDescription &&
                        sp.specialistDescription.descriptionUrl}
                    </Styled.DescriptionUrl>
                  </Styled.DoctorInfoListRecord>
                </Styled.Visit>
              </div>
            );
          })}
        {data && data.length !== 0 && specialistDescription?.length < data?.length && showLoadMoreButton && (
          <Styled.LoadMoreBtn>
            <Button
              loading={loading}
              onClick={() => setSpecialistDescriptionLength((prev) => prev + 10)}
            >
              Rodyti daugiau
            </Button>
          </Styled.LoadMoreBtn>
        )}
      </Styled.DataContainer>

      <Modal
        visible={editModalVisible}
        onClose={() => {
          setEditModalVisible(false);
        }}
        isGrey={true}
        wide={true}
      >
        <Modal.Edit
          link={selectedLink}
          changeReload={changeReload}
          selectedSpecialistId={selectedSpecialistId}
          setEditModalVisible={setEditModalVisible}
          specialistFullName={specialistFullName}
        ></Modal.Edit>
      </Modal>
    </Page>
  );
};
export default DoctorInformation;
