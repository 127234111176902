import { Field, Form as FormikForm, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useEffectOnce, useRafState } from 'react-use';
import useFetch from 'use-http';
import * as Yup from 'yup';
import { useUserDispatch, useUserState } from '../../context/UserContext';
import Button from '../../shared/Button';
import Container from '../../shared/Container';
import Form from '../../shared/Form';
import Page from '../../shared/Page';
import TextField from '../../shared/TextField';
import Styled from './SelfServicePage.styles';
import usePasswordToggle from '../../hooks/usePasswordToggle';
import useEmailToken from '../../hooks/useEmailToken';
import Modal from '../../shared/Modal/Modal';
import SessionStorage from '../../constants/sessionStorage';
import { useCartState, useCartDispatch } from '../../context/CartContext';
import useRedirection from '../../hooks/useRedirection';
import { useSettingsState } from '../../context/SettingsContext';
import validateEmail from '../../hooks/validateEmail';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const SelfService = () => {
  const sessionDispatch = useUserDispatch();
  const userState = useUserState();
  const dispatch = useCartDispatch();
  const [emailToken, setEmailToken] = useState('');
  const [emailCheckValue, setEmailCheckValue] = useState('');
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [pageLoad, setPageLoad] = useState(true);
  const { post: loginStep1Post, response: loginStep1Response } = useFetch(
    `/UserAccount/Login/Step1`,
  );

  const { theme } = useSettingsState();

  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [
    checkEmailToken,
    sendUpdate,
    resetNewEmail,
    confirmToken,
    emailChangeMessage,
    previewTokenConfirmModalVisible,
    setPreviewTokenConfirmModalVisible,
  ] = useEmailToken();

  const [clearCartState] = useRedirection();
  const redirectedFromUrl = location.state?.redirectedFromUrl;

  // Reset on page enter.
  useEffectOnce(() => {
    setPreviewTokenConfirmModalVisible(false);
    sessionDispatch({ type: 'RESET' });
    if (!!redirectedFromUrl?.pathname) {
      sessionDispatch({
        type: 'SET_FROM_URL_REDIRECT_TO_LOGIN',
        fromUrlRedirectToLogin: redirectedFromUrl?.pathname,
      });
    }
    const query = new URLSearchParams(window.location.search);
    setEmailToken(query.get('token'));
  });

  useEffect(() => {
    if (emailToken && !SessionStorage.getContent().emailTokenConfirmed) {
      (async function () {
        await confirmToken(emailToken);
      })();
    }
  }, [emailToken]);

  useEffect(() => {
    if (pageLoad) {
      const interval = setInterval(() => {
        setPageLoad(false);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [pageLoad]);

  useEffect(() => {
    return () => {
      clearCartState();
    };
  }, [clearCartState]);

  const initialValues = {
    emailOrPhone: '',
    password: '',
  };

  const types = {
    smartid: 'smartid',
    msign: 'msignature',
    credentials: 'credentials',
  };

  const handleSelection = async (type) => {
    history.push(`/login/${type}`);
  };

  const handlePasswordLogin = async (data, { setErrors, setSubmitting }) => {
    setSubmitting(true);
    const response = await loginStep1Post('', {
      email: data.emailOrPhone,
      password: data.password,
    });

    if (loginStep1Response.status >= 500) {
      const errors = {
        emailOrPhone: t('login.credentialsTab.badCredentials'),
      };
      setErrors(errors);
    } else if (loginStep1Response.status >= 400) {
      const errors = {
        emailOrPhone: t('login.credentialsTab.badCredentials'),
      };
      setErrors(errors);
    } else if (response.isBlocked) {
      const errors = {
        emailOrPhone: t('validation.blockedAccount'),
      };
      setErrors(errors);
    } else {
      sessionDispatch({
        type: 'SET_SESSION_ID',
        data: {
          sessionId: response.sessionId,
        },
      });
      sessionDispatch({
        type: 'UPDATE',
        data: {
          ...response.user,
          ...{ phoneNumberConfirmed: false },
        },
      });

      history.push('/verify/phone', { from: 'login' });
    }
    setSubmitting(false);
  };

  const validationSchema = Yup.object().shape({
    emailOrPhone: Yup.string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.requiredField'))
      .test('emailOrPhone', t('validation.invalidEmail'), function (value) {
        const emailRegex = /^\S+@\S+\.\S+$/; // Basic email check for @ and .
        //const emailExecptionRegex =
        // const phoneRegex = /^[+]{1}(370)(\d{8})+$/; //Checks only if its numbers and optional +
        //const exclusionRegex =
        let validEmail = emailRegex.test(value);
        if (!validEmail) {
          return false;
        }

        return true;
      })
      .test(
        'latinOnlyEmail',
        t('validation.latinOnlyEmail'),
        function (value) {
          if (!value) return true;
          const latinOnlyRegex = /^[\u0000-\u007F]+$/;
          return latinOnlyRegex.test(value);
        }
      )
      .test('emailOrPhone', t('validation.checkEmail'), function (value) {
        let validEmail2 = validateEmail(value);
        if (value === emailCheckValue && !validEmail2) {
          return true;
        }

        return true;
      })
      .test('emailOrPhone', t('validation.checkEmail'), function (value) {
        let validEmail2 = validateEmail(value);
        if (value === emailCheckValue && !validEmail2) {
          return true;
        }

        if (value !== emailCheckValue && !validEmail2) {
          setEmailCheckValue(value);
          return false;
        }

        return true;
      }),
    password: Yup.string().required(t('validation.requiredField')).trim(),
  });

  return (
    <Page loading={pageLoad}>
      <Container>
        <Styled.FormWrapper>
          <Styled.Form>
            <Styled.Title>{t('login.title')}</Styled.Title>
            <Styled.Buttons>
              <Button
                loading={false}
                disabled={false}
                size="mega"
                type="submit"
                style={{
                  width: 'inherit',
                  backgroundColor: theme.brandColor,
                  borderColor: theme.brandColor,
                }}
                onClick={() => handleSelection(types.smartid)}
              >
                {t('login.smartId')}
              </Button>
            </Styled.Buttons>
            <Styled.Buttons>
              <Button
                loading={false}
                disabled={false}
                size="mega"
                type="submit"
                style={{
                  width: 'inherit',
                  backgroundColor: theme.brandColor,
                  borderColor: theme.brandColor,
                }}
                onClick={() => handleSelection(types.msign)}
              >
                {t('login.msignature')}
              </Button>
            </Styled.Buttons>

            <Styled.StyledHr></Styled.StyledHr>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handlePasswordLogin}
            >
              {({ isSubmitting, values }) => (
                <FormikForm>
                  <Form>
                    <Form.Group>
                      <Form.Row>
                        <Field
                          isGrey={true}
                          name="emailOrPhone"
                          placeholder={t(
                            'login.credentialsTab.emailPlaceholder',
                          )}
                          component={TextField}
                        />
                      </Form.Row>
                      <Form.Row positionRelative="positionRelative">
                        <Field
                          isGrey={true}
                          name="password"
                          type={PasswordInputType}
                          placeholder={t(
                            'login.credentialsTab.passwordPlaceholder',
                          )}
                          component={TextField}
                        />
                        {values.password && (
                          <Styled.ToggleIcon>{ToggleIcon}</Styled.ToggleIcon>
                        )}
                      </Form.Row>
                    </Form.Group>
                  </Form>
                  <Styled.ForgotPassword>
                    <Link to={`/forgot-password`}>
                      {t('login.credentialsTab.forgotPassword')}
                    </Link>
                  </Styled.ForgotPassword>

                  <Styled.Buttons>
                    <Button
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      style={{
                        width: '100%',
                        backgroundColor: theme.brandColor,
                        borderColor: theme.brandColor,
                      }}
                      size="mega"
                      type="submit"
                      onClick={() => handlePasswordLogin}
                    >
                      {t('login.credentialsTab.submit')}
                    </Button>
                  </Styled.Buttons>
                </FormikForm>
              )}
            </Formik>
          </Styled.Form>
          <Styled.LineThrough>{t('login.alternative')}</Styled.LineThrough>
          <Styled.Form>
            <Styled.Title>{t('login.createAccount')}</Styled.Title>
            <Styled.RegisterHere>
              <span>{t('userRegistration.newAccount')}</span>
              <span>{t('userRegistration.registerHere')}</span>
            </Styled.RegisterHere>
            <Styled.Buttons>
              <Button
                loading={false}
                disabled={false}
                size="mega"
                type="submit"
                style={{
                  width: 'inherit',
                  backgroundColor: theme.brandColor,
                  borderColor: theme.brandColor,
                }}
                onClick={() => history.push('/register')}
              >
                {t('userRegistration.register')}
              </Button>
            </Styled.Buttons>
          </Styled.Form>
        </Styled.FormWrapper>
      </Container>
      <Modal
        visible={previewTokenConfirmModalVisible}
        onClose={() => {
          setPreviewTokenConfirmModalVisible(false);
        }}
      >
        <Modal.TextContent>{emailChangeMessage}</Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setPreviewTokenConfirmModalVisible(false);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};
export default SelfService;
