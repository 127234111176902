/* eslint-disable jsx-a11y/anchor-is-valid */
import useFetch from 'use-http';
import React, { useEffect, useState, useRef } from 'react';
import Page from '../../../../../shared/Page';
import Styled from '../../UserAccountPage.styles';
import Loader from '../../../../../shared/Loader';
import Button from '../../../../../shared/Button/Button';
import { AiOutlineEdit } from 'react-icons/ai';
import Modal from './Modal/Modal';

const ServiceInformation = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [fullData, setFullData] = useState();
  const [reload, setReload] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
  const [serviceDescription, setServiceDescription] = useState([]);
  const [selectedServiceExternalId, setSelectedServiceExternalId] = useState();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedLink, setSelectedLink] = useState('');
  const [serviceFullName, setServiceTitle] = useState('');
  const [serviceDescriptionLength, setServiceDescriptionLength] = useState(0);
  const { get: getServicesDescription } = useFetch(`/ServicesDescription`);
  const [search, setSearch] = useState('');

  const updateReload = useRef();

  const changeReload = () => {
    updateReload.current = true;
    setReload((prev) => !prev);
    setLoading(true);
  };

  useEffect(() => {
    setSearch('');
    setReload((prev) => !prev);
  }, []);

  useEffect(() => {
    setServiceDescription(data?.slice(0, serviceDescriptionLength));
  }, [data, serviceDescriptionLength]);

  useEffect(() => {
    const fetchData = async () => {
      await getServicesDescription().then((res) => {
        let sortedRes = res?.sort((a, b) => (a.title < b.title ? -1 : 1));
        setFullData(sortedRes ?? []);
        setData(sortedRes ?? []);
        if (updateReload.current) {
          setServiceDescription(sortedRes?.slice(0, serviceDescriptionLength));
          updateReload.current = false;
          setLoading(false);
          return;
        }
        setServiceDescription(sortedRes?.slice(0, 50));
        setServiceDescriptionLength(50);
        setLoading(false);
      });
    };
    fetchData();
  }, [reload]);

  useEffect(() => {
    if (!search) {
      setData(fullData);
    }
    const filteredData = fullData?.filter((item) => {
      return item.title.toLowerCase().includes(search.toLowerCase());
    });
    setData(filteredData);
  }, [search, fullData]);

  const clearSearchField = () => {
    setSearch('');
  };

  return (
    <Page>
      <Styled.DataContainer>
        <Styled.TabTitleWrapper>
          <Styled.TabTitle>Paslaugų informacija</Styled.TabTitle>
          <form
            autocomplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              setSearch(e.target.search.value);
            }}
            onChange={(e) => {
              if (
                e.target.value === undefined ||
                e.target.value === null ||
                e.target.value === ''
              ) {
                setSearch(e.target.value);
              }
            }}
            style={{ display: 'flex' }}
          >
            <input
              type="text"
              id="search"
              placeholder="Paieška"
              name="search"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
            <Button
              style={{ marginRight: '15px', fontSize: '16px' }}
              size="small"
              type="submit"
              onClick={clearSearchField}
            >
              Išvalyti
            </Button>
          </form>
        </Styled.TabTitleWrapper>

        {loading && (
          <Page>
            <Loader />
          </Page>
        )}

        {serviceDescription &&
          serviceDescription.length !== 0 &&
          serviceDescription.map((sp, index) => {
            return (
              <div key={index}>
                <Styled.Visit>
                  <Styled.DoctorInfoListRecord>
                    <div>
                      <AiOutlineEdit
                        onClick={() => {
                          setEditModalVisible(true);
                          setSelectedServiceExternalId(sp.serviceExternalId);
                          setSelectedLink(
                            sp.descriptionUrl ? sp.descriptionUrl : '',
                          );
                          setServiceTitle(
                            `${sp.title.toString().toUpperCase()}`,
                          );
                        }}
                      />
                      <Styled.DoctorName>
                        {sp.title.toString().toUpperCase()}
                      </Styled.DoctorName>
                    </div>
                    <Styled.DescriptionUrl>
                      {sp.descriptionUrl}
                    </Styled.DescriptionUrl>
                  </Styled.DoctorInfoListRecord>
                </Styled.Visit>
              </div>
            );
          })}
        {data &&
          data.length !== 0 &&
          serviceDescription?.length < data?.length &&
          showLoadMoreButton && (
            <Styled.LoadMoreBtn>
              <Button
                loading={loading}
                onClick={() => setServiceDescriptionLength((prev) => prev + 10)}
              >
                Rodyti daugiau
              </Button>
            </Styled.LoadMoreBtn>
          )}
      </Styled.DataContainer>

      <Modal
        visible={editModalVisible}
        onClose={() => {
          setEditModalVisible(false);
        }}
        isGrey={true}
        wide={true}
      >
        <Modal.Edit
          link={selectedLink}
          changeReload={changeReload}
          selectedServiceExternalId={selectedServiceExternalId}
          setEditModalVisible={setEditModalVisible}
          serviceFullName={serviceFullName}
        ></Modal.Edit>
      </Modal>
    </Page>
  );
};
export default ServiceInformation;
