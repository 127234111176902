/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Styled from './UserAccountPage.styles';
import Button from '../../../shared/Button';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/error-circle.svg';
import Moment from 'react-moment';
import Modal from '../../../shared/Modal/Modal';
import { useUserState, useUserDispatch } from '../../../context/UserContext';
import Page from '../../../shared/Page';
import moment from 'moment';
import { createStateContext, useEffectOnce } from 'react-use';
import useEmailToken from '../../../hooks/useEmailToken';
import useRedirection from '../../../hooks/useRedirection';
import addToAppleWallet from '../../../../assets/favicons/LT_Add_to_Apple_Wallet_RGB.svg';
import addToGoogleWallet from '../../../../assets/favicons/lt_add_to_google_wallet_add-wallet-badge.png';

const UserAccountPage = () => {
  const history = useHistory();
  const userState = useUserState();
  const sessionDispatch = useUserDispatch();

  const { t } = useTranslation();
  const [PreviewModalVisible, setPreviewModalVisible] = useState(false);
  const [PreviewDeleteConfirm, setDeleteConfirm] = useState(false);
  const [generalPractitionerUrl, setGeneralPractitionerUrl] = useState(null);
  const [
    consentSigningAdditionalInformation,
    setConsentSigningAdditionalInformation,
  ] = useState(false);
  const [consentSigningSelected, setConsentSigningSelected] = useState();

  const [consents, setConsents] = useState();
  const [signRequestUrl, setSignRequestUrl] = useState();
  const [showSignModal, setShowSignModal] = useState(false);
  const [PreviewEmailConfirmModalVisible, setPreviewEmailConfirmModalVisible] =
    useState(false);
  const [deleteEmailLinkModalVisible, setDeleteEmailLinkModalVisible] =
    useState(false);

  const [isGoogleDiscountCardPassLoading, setIsGoogleDiscountCardPassLoading] =
    useState(false);

  const [isAppleDiscountCardPassLoading, setIsAppleDiscountCardPassLoading] =
    useState(false);

  const [shouldShowUnifyPhoneNumberModal, setshouldShowUnifyPhoneNumberModal] =
    useState(false);

  const { get: getUserDiscountCard, response: getUserDiscountCardResponse } =
    useFetch(`/UserAccount/DiscountCard`);

  const { get: getConsents, response: getConsentsResponse } = useFetch(
    `/ConsentFrom/ConsentFroms`,
  );

  const { post: sendConsentLog, response: consentLogResponse } = useFetch(
    `Logs/ConsentForm/Modify/Init`,
  );

  const {
    get: getConsentsFromDetails,
    response: getConsentsFromDetailsResponse,
  } = useFetch(`/ConsentFrom/ConsentFromsDetails`);

  const {
    get: getAppleWalletPassDiscountCard,
    response: getAppleWalletPassDiscountCardResponse,
  } = useFetch(`/DigitalWallet/appleWalletpass`);

  const {
    get: getAddDiscountCardToGoogleWalletUrl,
    response: getAddDiscountCardToGoogleWalletUrlResponse,
  } = useFetch(`/DigitalWallet/googleWalletPass`);

  const { put: putUnifyPhoneNumber, response: putUnifyPhoneNumberResponse } =
    useFetch(`/UserAccount/unifyPhoneNumber`);

  useEffect(() => {
    async function fetchData() {
      let query = '';
      if (userState?.espbiEsi) {
        query = `?espbiEsi=${userState?.espbiEsi}`;
      }
      await getConsents(query).then((res) => {
        setConsents(
          res?.filter((x) => x.status === 'LaukiamaIsankstinioUzpildymo'),
        );
      });
    }
    fetchData();
  }, [userState?.espbiEsi]);

  const consentSelected = async (s) => {
    setConsentSigningAdditionalInformation(false);
    let query = `/${s.id}`;
    if (userState?.espbiEsi) {
      query = `/${s.id}?espbiEsi=${userState?.espbiEsi}`;
    }
    await getConsentsFromDetails(query).then((res) => {
      setSignRequestUrl(res.signRequestUrl);
      sendConsentFormLogToApi(res);
      setShowSignModal(true);
    });
  };

  const sendConsentFormLogToApi = (consent) => {
    sendConsentLog('', {
      id: consent.id,
      name: consent.name,
    });
  };

  const continueWithConsentSelected = async (s) => {
    var popupSetting = localStorage.getItem('consent-signing-popup');
    var expirationDate = new Date(popupSetting);
    if (popupSetting && expirationDate > new Date()) {
      await consentSelected(s);
    } else {
      let hours = 24;
      localStorage.setItem(
        'consent-signing-popup',
        new moment(new Date()).add(Number(hours), 'h').toDate(),
      );
      setConsentSigningAdditionalInformation(true);
      setConsentSigningSelected(s);
    }
  };

  const cancelConsentSigning = () => {
    setConsentSigningAdditionalInformation(false);
    setConsentSigningSelected(null);
  };

  const [
    checkEmailToken,
    sendUpdate,
    resetNewEmail,
    confirmToken,
    ,
    ,
    ,
    deleteNewEmail,
  ] = useEmailToken();

  const [
    clearCartState,
    performRedirectionOnCondition,
    performRedirectionToLoginRedirectedFrom,
  ] = useRedirection();

  const userAccountEditEnabled =
    process.env.REACT_APP_USER_ACCOUNT_EDIT_ENABLED === 'true';

  const {
    post: deleteUserAccountRequest,
    response: deleteUserAccountResponse,
  } = useFetch(`/UserAccount/Delete`);

  const handleDeleteEmailLink = () => {
    setDeleteEmailLinkModalVisible(true);
  };

  useEffect(() => {
    if (checkEmailToken().emailTokenExists) {
      if (userState.newEmail === null) {
        return;
      } else {
        setPreviewEmailConfirmModalVisible(true);
      }
    }
  }, [userState.newEmail]);

  useEffect(() => {
    document.body.style.zoom = '100%';
  }, []);

  useEffect(() => {
    if (
      !!userState.subscriptionInfo &&
      !!userState.subscriptionInfo.subscriptionPractitionerLocalId &&
      !!userState.subscriptionInfo.subscriptionPractitionerServiceTypeId
    ) {
      setGeneralPractitionerUrl(
        `services/1/${userState.subscriptionInfo.subscriptionPractitionerServiceTypeId}?specialistId=${userState.subscriptionInfo.subscriptionPractitionerLocalId}`,
      );
    }
  }, [userState]);

  useEffectOnce(async () => {
    const userCard = await getUserDiscountCard();
    if (getUserDiscountCardResponse.status == 200) {
      if (userCard) {
        sessionDispatch({
          type: 'UPDATE',
          data: {
            cardNumber: userCard.number,
            cardType: userCard.type,
            cardValidTo: userCard.validUntil,
            cardValidFrom: userCard.validFrom,
            cardTypeId: userCard.typeId,
          },
        });
      }
    }
  });

  useEffectOnce(async () => {
    await performRedirectionOnCondition();
    return () => {
      clearCartState();
    };
  }, [clearCartState, performRedirectionOnCondition]);

  useEffectOnce(async () => {
    await performRedirectionToLoginRedirectedFrom();
  }, [performRedirectionToLoginRedirectedFrom]);

  const downloadFile = (data, filename) => {
    const blob = new Blob([data], { type: 'application/vnd.apple.pkpass' });
    if (window.navigator.msSaveOrOpenBlob) {
      // For IE
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  const addApplePassToWallet = () => {
    setIsAppleDiscountCardPassLoading(true);
    getAppleWalletPassDiscountCard()
      .then((res) => {
        if (!!res.status && res.status !== 200) {
          setIsGoogleDiscountCardPassLoading(false);
          alert('Nepavyko atsisiųsti nuolaidų kortelės');
        } else {
          var binaryString = window.atob(res);
          var binaryLen = binaryString.length;
          var bytes = new Uint8Array(binaryLen);
          for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }
          downloadFile(bytes, 'discount_card.pkpass');
          setIsAppleDiscountCardPassLoading(false);
        }
      })
      .catch(() => {
        alert('Nepavyko atsisiųsti nuolaidų kortelės');
      });
  };

  const addGooglePassToWallet = () => {
    setIsGoogleDiscountCardPassLoading(true);
    getAddDiscountCardToGoogleWalletUrl()
      .then((res) => {
        if (!!res.status && res.status !== 200) {
          setIsGoogleDiscountCardPassLoading(false);
          alert('Nepavyko atsisiųsti nuolaidų kortelės');
        } else {
          window.location.href = res;
        }
      })
      .catch(() => {
        setIsGoogleDiscountCardPassLoading(false);
        alert('Nepavyko atsisiųsti nuolaidų kortelės');
      });
  };

  const shouldUnifyPhoneNumber = () => {
    return (
      !userState.phoneNumberUnificationAsked &&
      userState.phoneNumberConfirmed &&
      userState.phoneNumberInAmis &&
      userState.phoneNumber &&
      userState.phoneNumberInAmis !== userState.phoneNumber
    );
  };

  useEffect(() => {
    setshouldShowUnifyPhoneNumberModal(shouldUnifyPhoneNumber());
  }, []);

  const closeNumberUnificationModal = () => {
    setshouldShowUnifyPhoneNumberModal(false);
    sessionDispatch({
      type: 'UPDATE',
      data: {
        phoneNumberUnificationAsked: true,
      },
    });
  };

  function RenderUserSettings() {
    return (
      <Styled.DataContainer>
        <Styled.InfoTitle>
          {!userState.espbiEsi && (
            <> {t('userAccount.userInfoTab.userInfoTitle')}</>
          )}
          {userState.espbiEsi && <> Vaiko informacija</>}
          {userAccountEditEnabled && !userState.espbiEsi && (
            <span onClick={() => history.push('/account/edit')}>
              {t('userAccount.userInfoTab.edit')}
            </span>
          )}
        </Styled.InfoTitle>
        <Styled.InfoDetails>
          <Styled.InfoDetailsRow>
            <span>{t('userAccount.userInfoTab.name')}</span>
            {userState.espbiEsi && (
              <div>
                {
                  userState.contacts?.find(
                    (x) => x.espbiEsi === userState.espbiEsi,
                  ).name
                }
              </div>
            )}
            {!userState.espbiEsi && <div>{userState.firstName}</div>}
          </Styled.InfoDetailsRow>
          <Styled.InfoDetailsRow>
            <span>{t('userAccount.userInfoTab.surname')}</span>
            {userState.espbiEsi && (
              <div>
                {
                  userState.contacts?.find(
                    (x) => x.espbiEsi === userState.espbiEsi,
                  ).surname
                }
              </div>
            )}
            {!userState.espbiEsi && <div>{userState.lastName}</div>}
          </Styled.InfoDetailsRow>
          {!userState.espbiEsi && (
            <Styled.InfoEmail>
              <span>
                <span className="emailHead">
                  {t('userAccount.userInfoTab.email')}{' '}
                </span>
                {userState.newEmail ? (
                  <span>
                    {userState.email} (el.pašto adreso keitimo patvirtinimo
                    laiškas išsiųstas {userState.newEmail})
                  </span>
                ) : (
                  <span>{userState.email}</span>
                )}
              </span>
              {userState.newEmail && (
                <span
                  className="deleteEmail"
                  onClick={(e) => handleDeleteEmailLink()}
                >
                  {t('userAccount.userInfoTab.deleteEmail')}
                </span>
              )}
            </Styled.InfoEmail>
          )}
          {!userState.espbiEsi && (
            <Styled.PhoneNumberInfoRow>
              <span>{t('userAccount.userInfoTab.phone')}</span>
              <div>{userState.phoneNumber}</div>
              {!userState.phoneNumberConfirmed && (
                <Styled.PhoneNumberNotConfirmed>
                  <ErrorIcon></ErrorIcon>
                  <div>{t('validation.phoneNotConfirmed')}</div>
                </Styled.PhoneNumberNotConfirmed>
              )}
            </Styled.PhoneNumberInfoRow>
          )}
          {!!generalPractitionerUrl && (
            <Button
              size="medium"
              style={{ cursor: 'pointer', marginTop: '15px' }}
              onClick={() => {
                history.push(generalPractitionerUrl);
              }}
            >
              Registruotis pas šeimos gydytoją
            </Button>
          )}
        </Styled.InfoDetails>
        {!userState.espbiEsi && userState.discountCard && (
          <Styled.DiscountCard>
            <Styled.DiscountCardTitle>
              Nuolaidų kortelė{' '}
              {userState.cardType && '(' + userState.cardType + ')'}
            </Styled.DiscountCardTitle>
            <Styled.InfoDetailsRow>
              <span>Numeris:</span>
              <div>{userState.cardNumber}</div>
            </Styled.InfoDetailsRow>
            {(userState.cardValidTo && (
              <Styled.InfoDetailsRow>
                <span>Galioja:</span>
                <div>
                  {moment(userState.cardValidFrom).format('YYYY-MM-DD')} -{' '}
                  {moment(userState.cardValidTo).format('YYYY-MM-DD')}
                </div>
              </Styled.InfoDetailsRow>
            )) || (
              <Styled.InfoDetailsRow>
                <span>Galioja nuo:</span>
                <div>
                  {moment(userState.cardValidFrom).format('YYYY-MM-DD')}
                </div>
              </Styled.InfoDetailsRow>
            )}
            <img
              src={addToGoogleWallet}
              alt=""
              onClick={addGooglePassToWallet}
              style={{ cursor: 'pointer', marginTop: '10px' }}
              width="180"
              height="50"
            />
            <img
              src={addToAppleWallet}
              alt=""
              onClick={addApplePassToWallet}
              style={{ cursor: 'pointer', marginTop: '10px' }}
              width="180"
              height="50"
            />
          </Styled.DiscountCard>
        )}

        {consents && consents.length > 0 && (
          <Styled.InfoTitle
            noBottomBorder
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <p style={{ margin: '0', color: 'red' }}>
              Laukiantys išankstinio užpildymo sutikimai:
            </p>
            <div style={{ marginTop: '30px' }}>
              {consents.map((c) => {
                return (
                  <div key={c.id}>
                    <span
                      onClick={async () => await continueWithConsentSelected(c)}
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {c.name}
                    </span>
                  </div>
                );
              })}
            </div>
          </Styled.InfoTitle>
        )}
        {consents && consents.length > 0 && (
          <Styled.InfoTitle noTopBorder>
            <div
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => {
                history.push('/account/consent-forms');
              }}
            >
              Pasirašyti sutikimai
            </div>
          </Styled.InfoTitle>
        )}
        {(!consents || consents.length === 0) && (
          <Styled.InfoTitle>
            <div
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => {
                history.push('/account/consent-forms');
              }}
            >
              Pasirašyti sutikimai
            </div>
          </Styled.InfoTitle>
        )}

        <Styled.InfoTitle noTopBorder>
          Įgalioti asmenys ir rinkodaros sutikimai
          <span onClick={() => history.push('/account/manage-agreements')}>
            {t('userAccount.userInfoTab.agreementsManage')}
          </span>
        </Styled.InfoTitle>
        {/* po-733 commented */}
        {/* <Styled.InfoTitle noTopBorder>
          Privataus draudimo kortelės
          <span onClick={() => history.push('/account/insurance-cards')}>
            {t('userAccount.userInfoTab.agreementsManage')}
          </span>
        </Styled.InfoTitle> */}
        {!userState.espbiEsi && (
          <>
            <Styled.InfoTitle noTopBorder>
              {t('userAccount.userInfoTab.loginInfoTitle')}
              <span onClick={() => history.push('/account/change-password')}>
                {t('userAccount.userInfoTab.changePassword')}
              </span>
            </Styled.InfoTitle>

            {userState.scheduledToDeleteDate ? (
              <Styled.DeleteWarning>
                <div>
                  {t('userAccount.userInfoTab.deleteConfirm.deleteInfo')}
                  <Moment className="time" format="YYYY-MM-DD">
                    {userState.scheduledToDeleteDate}
                  </Moment>
                </div>
                <span onClick={() => handleUserAccountDeleteConfirm()}>
                  {t('userAccount.userInfoTab.deleteConfirm.cancelDelete')}
                </span>
              </Styled.DeleteWarning>
            ) : (
              <Styled.InfoTitle noTopBorder>
                {t('userAccount.userInfoTab.accountTitle')}
                <span onClick={() => setDeleteConfirm(true)}>
                  {t('userAccount.userInfoTab.deleteAccount')}
                </span>
              </Styled.InfoTitle>
            )}

            {/* <Styled.InfoTitle>
              Sutikimų formos
              <span onClick={() => history.push('/account/consent-forms')}>
                Valdyti
              </span>
            </Styled.InfoTitle> */}
          </>
        )}
      </Styled.DataContainer>
    );
  }

  const handleUserAccountDeleteConfirm = async () => {
    const response = await deleteUserAccountRequest('', {
      id: userState.id,
    });
    if (deleteUserAccountResponse.ok) {
      sessionDispatch({
        type: 'UPDATE',
        data: {
          scheduledToDeleteDate: response,
        },
      });
    }
    setDeleteConfirm(false);
  };

  const isLoading = () => {
    return isGoogleDiscountCardPassLoading || isAppleDiscountCardPassLoading;
  };

  return (
    <Page loading={isLoading()}>
      {RenderUserSettings()}
      <Modal
        visible={PreviewModalVisible}
        onClose={() => {
          setPreviewModalVisible(false);
        }}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          <p>
            <Trans
              i18nKey="userAccount.visitTab.modal.price"
              values={{ price: 10 }}
              components={{ bold: <strong /> }}
            />
          </p>
          <p>{t('userAccount.visitTab.modal.confirm')} </p>
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            isGrey={true}
            onClick={() => setPreviewModalVisible(false)}
          >
            {t('userAccount.visitTab.modal.cancel')}
          </Button>
          <Button size="large" onClick={() => setPreviewModalVisible(false)}>
            {t('userAccount.visitTab.modal.continue')}
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        visible={PreviewDeleteConfirm}
        loading={false}
        onClose={() => setDeleteConfirm(false)}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          <p>
            <Trans
              i18nKey="userAccount.userInfoTab.deleteConfirm.confirm"
              values={{ time: process.env.REACT_APP_USER_ACCOUNT_DELETE_TIME }}
              components={{ bold: <strong /> }}
            />
          </p>
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={() => setDeleteConfirm(false)}
            isGrey={true}
          >
            {t('userAccount.userInfoTab.deleteConfirm.cancel')}
          </Button>
          <Button
            size="large"
            loading={false}
            onClick={() => handleUserAccountDeleteConfirm()}
          >
            {t('userAccount.userInfoTab.deleteConfirm.continue')}
          </Button>
        </Modal.Actions>
      </Modal>
      {/* naujas */}
      <Modal
        visible={consentSigningAdditionalInformation}
        loading={false}
        onClose={() => cancelConsentSigning()}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          <p>
            <Trans
              i18nKey="Man žinoma, kad pacientas elektroniniu būdu pildomus dokumentus taip pat turi teisę užpildyti atvykęs į 
              padalinį (atsisakyti pildyti elektroninį dokumentą, nutraukti jo pildymą ir pan.).<br/>
              Nepriklausomai nuo to, ar užpildytas elektroninis dokumentas buvo pateiktas ar ne, 
              pacientas atvykęs į padalinį turi teisę pateikti mūsų specialistui bet kokius jam rūpimus klausimus,<br/>
              susijusius su gaunamomis paslaugomis, ir gauti su tuo susijusius paaiškinimus."
              components={{ bold: <strong /> }}
            />
          </p>
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={() => cancelConsentSigning()}
            isGrey={true}
          >
            Grįžti
          </Button>
          <Button
            size="large"
            loading={false}
            onClick={async () => await consentSelected(consentSigningSelected)}
          >
            Tęsti
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={PreviewEmailConfirmModalVisible}
        onClose={() => {
          resetNewEmail(checkEmailToken().emailToken);
          setPreviewEmailConfirmModalVisible(false);
        }}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          {`Ar norite pakeisti savo el.pašto adresą iš ${userState.email} į ${userState.newEmail}?`}
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              sendUpdate(checkEmailToken().emailToken);
              setPreviewEmailConfirmModalVisible(false);
            }}
          >
            Taip
          </Button>
          <Button
            size="large"
            onClick={() => {
              resetNewEmail(checkEmailToken().emailToken);
              setPreviewEmailConfirmModalVisible(false);
            }}
          >
            Ne
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={deleteEmailLinkModalVisible}
        onClose={() => {
          setDeleteEmailLinkModalVisible(false);
        }}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          {`Ar tikrai norite atšaukti el.pašto adreso keitimą?`}
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant={'secondary'}
            onClick={() => {
              setDeleteEmailLinkModalVisible(false);
            }}
            isGrey={true}
          >
            Atsisakyti
          </Button>
          <Button
            size="large"
            onClick={() => {
              deleteNewEmail();
              setDeleteEmailLinkModalVisible(false);
            }}
          >
            Atšaukti keitimą
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={showSignModal}
        onClose={() => {
          setShowSignModal(false);
          setSignRequestUrl(undefined);
        }}
        isGrey={true}
        fullScreen={true}
      >
        <iframe
          style={{ height: '100%', width: '100%' }}
          id="sontIframe"
          src={signRequestUrl}
          allow="camera"
        />
        <Modal.Actions>
          <Button
            size="large"
            onClick={async () => {
              window.location.reload();
            }}
          >
            Uždaryti
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={shouldShowUnifyPhoneNumberModal}
        onClose={() => {
          closeNumberUnificationModal();
        }}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          Jūsų telefono numeris {userState.phoneNumberInAmis} nurodytas Affidea
          padalinyje nesutampa su telefonu {userState.phoneNumber} nurodytu
          portale mano.affidea.lt
          <br />
          Ar norite pakeisti ir portale naudoti numerį{' '}
          {userState.phoneNumberInAmis}?
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              closeNumberUnificationModal();
              putUnifyPhoneNumber({ phoneNumber: userState.phoneNumber });
            }}
          >
            Naudoti turimą numerį
          </Button>
          <Button
            size="large"
            onClick={() => {
              closeNumberUnificationModal();
              putUnifyPhoneNumber({ phoneNumber: userState.phoneNumberInAmis });
            }}
          >
            Pakeisti numerį
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};

export default UserAccountPage;
