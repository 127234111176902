import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import './translations';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './assets/styles/core.scss';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import Clarity from '@microsoft/clarity';
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

const getAllowed = () => {
  const ls = localStorage.getItem('cookies');
  return ls ? ls.split(',') : [];
};

if (getAllowed().includes('analysis')) {
  Clarity.init(process.env.REACT_APP_CLARITY_PROJECT_ID);
  console.log("CLARITY STARTED");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
