import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Styled from '../../UserAccountPage.styles';
import Button from '../../../../../shared/Button';
import Page from '../../../../../shared/Page';
import * as Yup from 'yup';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import { Formik, Form as FormikForm, Field } from 'formik';
import Form from '../../../../../shared/Form';
import TextField from '../../../../../shared/TextField';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';
import {
  useUserState,
  useUserDispatch,
} from '../../../../../context/UserContext';
import Modal from '../../../../../shared/Modal/Modal';
import validateEmail from '../../../../../hooks/validateEmail';
import { PhoneNumberType } from 'google-libphonenumber';

const AccountInfoEdit = () => {
  const userState = useUserState();
  const sessionDispatch = useUserDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
  const [PreviewModalVisible, setPreviewModalVisible] = useState(false);
  const [emailCheckValue, setEmailCheckValue] = useState('');

  const {
    post: updateUserAccountRequest,
    response: updateUserAccountResponse,
  } = useFetch(`/UserAccount/Update`);

  const { post: sendSmsConfirmation, response: sendSmsConfirmationResponse } =
    useFetch(`/UserAccount/sendsmsconfirmation`);

  const initialValues = {
    phoneNumber: userState.phoneNumber,
    email: userState.email,
  };

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(t('validation.requiredField'))
      .test('phoneTest', function (val) {
        if (!val) {
          throw new Yup.ValidationError(t('validation.requiredField'), val, 'phoneNumber');
        }
        const standardPhoneRegex = /^\+(?:\d+)?$/;
        if (!standardPhoneRegex.test(val)) {
          throw new Yup.ValidationError(`Netinkami simboliai. Naudokite tik '+' ženklą ir skaičius po jo.`, val, 'phoneNumber');
        }
        try {
          const number = phoneUtil.parseAndKeepRawInput(val);
          const isNumberValid = phoneUtil.isValidNumber(number);
          if (!isNumberValid) {
            throw new Yup.ValidationError(`Netinkamas numerio formatas arba ilgis`, val, 'phoneNumber');
          }
          const isNumberMobile =
            phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE ||
            phoneUtil.getNumberType(number) === PhoneNumberType.FIXED_LINE_OR_MOBILE;

          if (!isNumberMobile) {
            throw new Yup.ValidationError(`Turi būti mobilaus telefono numeris`, val, 'phoneNumber');
          }
        } catch (error) {
          console.log(error);
          throw new Yup.ValidationError(`Netinkamas numerio formatas arba ilgis`, val, 'phoneNumber');
        }
        return true;
      })
      .trim(),
    email: Yup.string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.requiredField'))
      .trim()
      .test('emailTest', t('validation.checkEmail'), function (value) {
        let validEmail2 = validateEmail(value);
        if (value === emailCheckValue && !validEmail2) {
          return true;
        }
        if (value !== emailCheckValue && !validEmail2) {
          setEmailCheckValue(value);
          return false;
        }
        return true;
      })
      .test(
        'latinOnlyEmail',
        t('validation.latinOnlyEmail'),
        function (value) {
          if (!value) return true;
          const latinOnlyRegex = /^[\u0000-\u007F]+$/;
          return latinOnlyRegex.test(value);
        }
      ),
  });

  const handleAccountInfoEdit = async (data, { setSubmitting, setErrors }) => {
    data.email = data.email.trim();
    data.phoneNumber = data.phoneNumber.trim();

    let phoneNumberChanged = false;
    if (
      userState.email === data.email &&
      userState.phoneNumber === data.phoneNumber
    ) {
      const errors = {
        email: t('validation.emailMatch'),
        phoneNumber: t('validation.phoneMatch'),
      };
      setErrors(errors);
      return;
    }
    // if phone number changed - send sms, but do not change for user now
    if (userState.phoneNumber !== data.phoneNumber) {
      await sendSmsConfirmation('', { phoneNumber: data.phoneNumber });
      phoneNumberChanged = true;
    }

    //Update newEmail in database
    const response = await updateUserAccountRequest('', {
      id: userState.id,
      email: userState.email,
      newEmail: data.email,
      firstName: userState.firstName,
      lastName: userState.lastName,
      phoneNumber: userState.phoneNumber,
    });

    if (updateUserAccountResponse.status > 199) {
      if (response.status === 400) {
        const errors = {
          email: t('validation.takenEmailV2'),
        };
        setErrors(errors);
      } else {
        sessionDispatch({
          type: 'UPDATE',
          data: {
            id: response.id,
            email: response.email,
            phoneNumber: response.phoneNumber,
            personalCode: userState.personalCode,
            firstName: response.firstName,
            lastName: response.lastName,
            countryCode: userState.countryCode,
            phoneNumberConfirmed: response.phoneNumberConfirmed,
            newEmail: response.newEmail,
          },
        });
        if (!phoneNumberChanged) {
          setPreviewModalVisible(true);
        } else {
          history.push('/verify/phone', {
            from: 'edit',
            phoneNumber: data.phoneNumber,
          });
        }
        setNewEmail(data.email);
      }
    }
    setSubmitting(false);
  };

  const [NewEmail, setNewEmail] = useState('');

  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.BackArrow>
          <RightArrow onClick={() => history.goBack()}></RightArrow>
          <span>{t('userAccount.userInfoTab.userInfoTitle')}</span>
        </Styled.BackArrow>
        <Styled.EditForm>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleAccountInfoEdit}
          >
            {({ setFieldValue, errors, isSubmitting, values }) => (
              <FormikForm>
                <Form>
                  <Form.Group>
                    <Form.Row>
                      <Field
                        focus={true}
                        name="phoneNumber"
                        label={t('userAccount.userAccountInfoEdit.phoneNumber')}
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                    <Form.Row>
                      <Field
                        name="email"
                        label={t('userAccount.userAccountInfoEdit.email')}
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                  </Form.Group>
                </Form>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  onClick={() => handleAccountInfoEdit}
                >
                  {t('userAccount.userInfoTab.updateData')}
                </Button>
              </FormikForm>
            )}
          </Formik>
        </Styled.EditForm>
      </Styled.DataContainer>
      <Modal
        visible={PreviewModalVisible}
        onClose={() => setPreviewModalVisible(false)}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          {`Inicijuotas el. pašto keitimas. Keitimo patvirtinimas išsiųstas į ${NewEmail}`}
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setPreviewModalVisible(false);
              history.goBack();
            }}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};
export default AccountInfoEdit;
